import React from 'react'
import { translate } from "react-i18next"

import Layout from '../components/layouts/baseLayout'
import Form from '../components/contact_form/form'
import Navbar from '../components/navbar/navbar'
import MissingTranslation from '../components/missing_translation/missing_translation'

import allStyle from "../styles/all.module.scss"


function Terms (props) {
  const { t } = props;
  return (
    <Layout>

      <main className={allStyle.annexe}>
        <div className={allStyle.page}>
          <MissingTranslation/>
          <div className={allStyle.title_links}>
            <h3>{t("terms")}</h3>
            <a href="#un">{t("plateform_object")}</a>
            <a href="#deux">{t("plateform_target")}</a>
            <a href="#trois">{t("plateform_use")}</a>
            <a href="#quatre">{t("plateform_responsability")}</a>
            <a href="#cinq">{t("plateform_intelectual_rights")}</a>
            <a href="#six">{t("plateform_rights")}</a>
            <a href="#sept">{t("plateform_contact")} </a>

          </div>
          <div id="un">
            <h3 className={allStyle.section_title}>{t("plateform_object")}</h3>
            <p>{t("plateform_object_text_part_one")}</p>
            <p>{t("plateform_object_text_part_two")}</p>
          </div>
          <div id="deux">
            <h3 className={allStyle.section_title}>{t("plateform_target")}</h3>
            <p>{t("plateform_target_text_part_one")} </p>
            <p>{t("plateform_target_text_part_two")}</p>
            <p>{t("plateform_target_text_part_three")}</p>
            <p>{t("plateform_target_text_part_four")}

            </p>
          </div>
          <div id="trois">
            <h3 className={allStyle.section_title}>{t("plateform_use")}</h3>
            <p>{t("plateform_use_text_part_one")}</p>
            <p>{t("plateform_use_text_part_two")} </p>
            <p>{t("plateform_use_text_part_three")}</p>
            <p>{t("plateform_use_text_part_four")}</p>
          </div>
          <div id="quatre">
            <h3 className={allStyle.section_title}>{t("plateform_responsability")}</h3>
            <p>{t("plateform_responsability_text_part_one")}</p>
            <p>{t("plateform_responsability_text_part_two")}</p>
            <p>{t("plateform_responsability_text_part_three")}</p>
            <p>{t("plateform_responsability_text_part_four")}</p>
          </div>
          <div id="cinq">
            <h3 className={allStyle.section_title}>{t("plateform_intelectual_rights")}</h3>
            <p>{t("plateform_intelectual_rights_text_part_one")}</p>
            <p>{t("plateform_intelectual_rights_text_part_two")}</p>
            <p>{t("plateform_intelectual_rights_text_part_three")}</p>
            <p>{t("plateform_intelectual_rights_text_part_four")}</p>
            <a href="http://www.ulg.ac.be">http://www.ulg.ac.be</a>
          </div>
          <div id="six">
            <h3 className={allStyle.section_title}>{t("plateform_rights")}</h3>
            <p>{t("plateform_rights_text_part_one")}</p>
            <p>{t("plateform_rights_text_part_two")}</p>
          </div>
          <div id="sept">
            <h3 className={allStyle.section_title}>{t("plateform_contact")}</h3>
            <p>{t("plateform_contact_text")}
            </p>
            <Form/>
          </div>



        </div>
      </main>
    </Layout>)
}


export default translate("terms")(Terms)